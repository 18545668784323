var $name="analytics/contact",$path="app/modules/analytics/contact.js",$this={$name,$path,};import getSlugs from 'app/utilities/getSlugs';
import lookupSlugs from 'app/utilities/lookupSlugs';

/**
 *
 * @param { { contactCategory: string, requestTypeURL: string } } variables
 * @returns
 */
const contactSubmitProvider = ({
  requestTypeURL,
}, config) => {
  const { pathname } = new URL(requestTypeURL, window.location.href);
  const formType = lookupSlugs(
    config.requestType ?? {},
    getSlugs(pathname),
    pathname,
  );
  return {
    contactCategory: formType,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { contactSubmitProvider };
