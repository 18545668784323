var $class="se2--captcha",$name="captcha",$path="app/partials/captcha/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import { footerCheckboxContainerSelector } from 'app/components/FooterSE/config';

import validateCaptchaResponse from 'app/components/utilities/validateCaptchaResponse';

import { css, messages } from 'configs';

import {
  validationErrorMessageClassName,
  responseTokenClassName,
} from './config';

const recaptchaSelector = '.js-recaptcha';
const containerSelector = '.js-recaptcha-container';
const verificationSelector = '.js-verification-error-message';
const loaderSelector = '.dynamic-loader';
const urlParameters = '?onload=onLoadRecaptchaCallBack&render=explicit';

export default shell.registerPartial($this, ({
  addEventListener, log, mount, publishMessage, subscribeToMessage,
}) => {
  const recaptcha = (form) => {
    let instance = null;

    const recaptchaElement = form.querySelector(recaptchaSelector);
    const verificationElement = form.querySelector(verificationSelector);
    const validationElement = form.querySelector(`.${validationErrorMessageClassName}`);
    const loaderElement = form.querySelector(loaderSelector);
    const submitButtonElement = form.querySelector(`.${css.classNames.submitButton}`);
    const recaptchaId = form.querySelector(containerSelector)?.id;
    const footerCheckboxElement = form.querySelector(footerCheckboxContainerSelector);

    let isRecaptchaScriptWillLoad = false;
    const addRecaptchaScript = () => {
      if (!window.grecaptcha && !isRecaptchaScriptWillLoad) {
        const { url } = recaptchaElement.dataset;
        const countryCode = recaptchaElement.dataset.countryCode?.toLowerCase();
        const languageCode = recaptchaElement.dataset.languageCode?.toLowerCase();

        publishMessage(messages.recaptcha, { isLoading: true });
        if (url) {
          const scriptElement = document.createElement('script');
          scriptElement.src = `${url}${urlParameters}`;

          if (languageCode && countryCode) {
            const languageSettings = `${languageCode}-${countryCode}`;
            scriptElement.src += `&hl=${languageSettings}`;
          }

          scriptElement.async = true;
          scriptElement.onload = () => {
            publishMessage(messages.recaptcha, { resolved: true, recaptchaId });
          };
          scriptElement.onerror = (error) => {
            publishMessage(messages.recaptcha, { resolved: false, error });
          };

          document.body.appendChild(scriptElement);

          isRecaptchaScriptWillLoad = true;
        } else {
          publishMessage(messages.recaptcha,
            { resolved: false, error: 'Recaptcha download error: no URL present for downloading script' });
        }
      } else {
        publishMessage(messages.recaptcha, { resolved: true, recaptchaId });
      }
    };

    const handleRecaptchaSuccessAction = () => {
      publishMessage(messages.recaptcha, { successAction: true });
      verificationElement.classList.toggle(css.classNames.hide, true);
      validationElement.classList.toggle(css.classNames.hide, true);
    };

    const showRecaptchaVerificationErrorMessage = () => {
      verificationElement.classList.toggle(css.classNames.hide, false);
    };

    const showRecaptchaExpiredErrorMessage = () => {
      validateCaptchaResponse(form);
      // grecaptcha.reset() is firing before recaptcha expired functionality setTimeout fix it
      instance && setTimeout(() => window.grecaptcha.reset(instance), 0);
    };

    const removeLoader = () => {
      loaderElement.classList.remove('se2-icon-loader');
    };

    const addLoader = () => {
      loaderElement.classList.add('se2-icon-loader');
    };

    const recaptchaLoaded = () => {
      if (recaptchaId) {
        instance = window.grecaptcha.render(recaptchaId, {
          'sitekey': recaptchaElement.dataset.sitekey,
          'callback': handleRecaptchaSuccessAction,
          'expired-callback': showRecaptchaExpiredErrorMessage,
          'error-callback': showRecaptchaVerificationErrorMessage,
        });

        const responseToken = recaptchaElement.querySelector(`.${responseTokenClassName}`);
        responseToken?.setAttribute('required', '');
      }
    };

    const handleRecaptchaLoaded = ({ isLoading }) => {
      if (isLoading === true) addLoader();
      else if (isLoading === false) {
        removeLoader();
        recaptchaLoaded();
      }
    };

    const initOnloadCallback = () => {
      if (!window.onLoadRecaptchaCallBack) {
        // onLoadRecaptchaCallBack - is the default recaptcha method - fobiden to delete
        window.onLoadRecaptchaCallBack = () => publishMessage(messages.recaptcha, { isLoading: false });
      }
    };

    const revealRecaptcha = ({ resolved, recaptchaId: currentId, error }) => {
      if (currentId !== recaptchaId) return;
      if (resolved === true) {
        recaptchaElement.classList.remove(css.classNames.hide);
        if (submitButtonElement && !footerCheckboxElement) submitButtonElement.disabled = false;
      } else if (resolved === false) log.error('Script download failed:', error);
    };

    const resetRecaptcha = () => instance !== null && window.grecaptcha.reset(instance);

    if (recaptchaElement) {
      subscribeToMessage(messages.recaptcha, handleRecaptchaLoaded);
      subscribeToMessage(messages.recaptcha, revealRecaptcha);

      initOnloadCallback();

      addEventListener(form, 'focusin', addRecaptchaScript);
      subscribeToMessage(messages.formSubmit, resetRecaptcha);
    }
  };

  const shouldCaptchaMount = () => !!document.querySelector(recaptchaSelector);
  mount((element) => {
    if (!shouldCaptchaMount()) return;
    const form = element.closest('form');
    recaptcha(form);
  });
});
