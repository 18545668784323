var $name="shell/settings",$path="app/modules/shell/settings.js",$this={$name,$path,};import isAnalyticsEnabled from 'app/modules/analytics/isEnabled';
import isBreakpoint from 'app/utilities/isBreakpoint';
import isDirection from 'app/utilities/isDirection';
import getDocumentDirection from 'app/utilities/getDocumentDirection';
import getPageSettings from 'app/utilities/getPageSettings';
import getPlatformSettings from 'app/utilities/getPlatformSettings';
import toBoolean from 'app/utilities/toBoolean';
import configs from 'configs';

import constant from 'lodash/constant';
import forEach from 'lodash/forEach';
import forOwn from 'lodash/forOwn';
import identity from 'lodash/identity';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';

const localStorage = 'localStorage';
const sessionStorage = 'sessionStorage';

export const ACCESSIBILITY = 'accessibility';
export const ANALYTICS = 'analytics';
export const BREAKPOINT = 'breakpoint';
export const DEBUG = 'debug';
export const DIRECTION = 'direction';
export const PAGE = 'page';
export const PLATFORM = 'platform';

const keys = [ACCESSIBILITY, ANALYTICS, BREAKPOINT, DEBUG, DIRECTION, PAGE, PLATFORM];

const defaults = {
  [ACCESSIBILITY]: false,
  [ANALYTICS]: isAnalyticsEnabled,
  [BREAKPOINT]: configs.breakpoints.xs.key,
  [DEBUG]: () => process.env.NODE_ENV === configs.envs.development,
  [DIRECTION]: getDocumentDirection,
  [PAGE]: getPageSettings,
  [PLATFORM]: getPlatformSettings,
};

forEach(
  defaults,
  (value, key) => Object.defineProperty(
    defaults,
    key,
    isFunction(value)
      ? { get: value }
      : { value },
  ),
);

const currents = {
  [ACCESSIBILITY]: {
    convert: toBoolean,
    override: true,
    store: localStorage,
  },
  [ANALYTICS]: {
    convert: toBoolean,
    override: true,
  },
  [BREAKPOINT]: {
    validate: isBreakpoint,
  },
  [DEBUG]: {
    convert: toBoolean,
    override: true,
    store: sessionStorage,
  },
  [DIRECTION]: {
    override: true,
    validate: isDirection,
  },
  [PAGE]: {},
  [PLATFORM]: {},
};

forEach(
  currents,
  (current, key) => {
    current.convert = current.convert ?? identity;
    current.key = key;
    current.message = configs.messages[key];
    current.validate = current.validate ?? constant(true);
    // current.value = defaults[key];
  },
);

/**
 * @type { ISettings }
 */
const settings = reduce(
  keys,
  // eslint-disable-next-line no-shadow
  (settings, key) => Object.defineProperty(settings, key, {
    enumerable: true,
    get: () => currents[key].value ?? defaults[key],
  }),
  {},
);

function dispose() {
  forOwn(
    currents,
    (current) => {
      current.value = undefined;
    },
  );
}

export const prefix = (key) => `${configs.namespaces.public}.${key}`;

function update(current, value) {
  const { convert, key, validate, store } = current;
  if (validate(value)) {
    current.value = convert
      ? convert(value)
      : value;
    if (store) {
      window[store].setItem(prefix(key), current.value);
    }
  }
}

function load() {
  let hash;
  try {
    hash = new URLSearchParams(
      window.location.hash.substring(1).toLowerCase(),
    );
  } catch {
    // ignore error
  }

  forEach(
    currents,
    (current) => {
      const { key, override, store } = current;
      let value;
      if (store) value = window[store].getItem(prefix(key));
      if (override) value = hash.get(key) ?? value;
      if (value != null) update(current, value);
    },
  );
}

const subscribers = mapValues(
  pickBy(
    currents,
    ({ message }) => isString(message),
  ),
  (current) => (value) => update(current, value),
);

Object.setPrototypeOf(settings, {
  dispose, load, subscribers,
});

export default settings;
