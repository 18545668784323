var $name="shell/engine/applyUtmParams",$path="app/modules/shell/engine/applyUtmParams.js",$this={$name,$path,};import settings from 'app/modules/shell/settings';

const utmParamsList = ['utm_source', 'utm_tactic', 'utm_Tactic', 'utm_term', 'utm_campaign'];
const expiresPostfix = '_expires';
const maxAge = 30 * 60000; // 30 minutes

const checkExpirationDate = (key) => parseInt(localStorage.getItem(key + expiresPostfix), 10) >= Date.now();

const removeExpireItem = (key) => {
  localStorage.removeItem(key);
  localStorage.removeItem(key + expiresPostfix);
};

const addExpireItem = (key, value) => {
  localStorage.setItem(key, value);
  localStorage.setItem(key + expiresPostfix, Date.now() + maxAge);
};

const getParams = (name) => {
  const result = (new RegExp(`[?&]${encodeURIComponent(name)}=([^&]*)`))
    .exec(`?${window.location.href.split('?')[1]}`);
  return result === null ? undefined : result[1];
};

const saveUtmParam = () => {
  utmParamsList.forEach((paramName) => {
    const utmParam = getParams(paramName);
    utmParam && addExpireItem(`${paramName.toLowerCase()}_${settings.page.countryCode}`, utmParam);
  });
};

const updateUtmRelatedLinksWithUtmParams = () => {
  // Update .utm-href links with utm params
  const utmDependantLinks = Array.from(document.querySelectorAll('.utm-href'))
    .filter((link) => link.dataset.utmProcesses !== 'true');
  if (utmDependantLinks.length === 0) return;
  const utmMarkersArray = ['utm_source', 'utm_campaign', 'utm_term', 'utm_tactic'];
  const utmHrefParamsArray = [];

  utmMarkersArray.forEach((utmMarker) => {
    const localStorageUtmMarkerValue = localStorage.getItem(`${utmMarker}_${settings.page.countryCode}`);
    if (localStorageUtmMarkerValue) {
      utmHrefParamsArray.push(`${utmMarker}=${localStorageUtmMarkerValue}`);
    }
  });

  const utmHrefParamsString = utmHrefParamsArray.join('&');

  utmDependantLinks.forEach((elem) => {
    const href = elem.getAttribute('href');
    const linkingSign = (href.indexOf('?') !== -1) ? '&' : '?';
    elem.setAttribute('href', href + linkingSign + utmHrefParamsString);
    elem.dataset.utmProccesed = 'true';
  });
};

const applyUtmParams = () => {
  const url = window.location.href;
  const purposeIsMarketo = url.match(/utm_purpose=marketo/i);
  const utmCountry = `utm_country_${settings.page.countryCode}`;

  if (purposeIsMarketo) {
    if (localStorage.getItem(utmCountry)) {
      !checkExpirationDate(utmCountry) && removeExpireItem(utmCountry);
    } else if (!localStorage.getItem(utmCountry)) {
      addExpireItem(utmCountry, settings.page.countryCode);
      saveUtmParam();
    }
  }

  if (Date.now() > Number(localStorage.getItem(utmCountry + expiresPostfix))) {
    utmParamsList.push('utm_country');
    utmParamsList.forEach((paramName) => {
      removeExpireItem(`${paramName.toLowerCase()}_${settings.page.countryCode}`);
    });
  }

  updateUtmRelatedLinksWithUtmParams();
};

export default applyUtmParams;
