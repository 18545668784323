var $name="sticky/setStickyAnimation",$path="app/modules/sticky/setStickyAnimation.js",$this={$name,$path,};import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

import messages from 'configs/messages';
import breakpoints from 'configs/breakpoints';
import parseDecimal from 'app/utilities/parseDecimal';
import { metabarHeightValue } from 'app/components/HeaderSE/config';
import checkHeaderExistence from 'app/components/utilities/checkHeaderExistence';
import recalculateStickyPositions from './recalculateStickyPositions';
import calculateTotalStickyHeight from './calculateTotalStickyHeight';

const initGsapAnimation = (element, marker, subscribeToMessage) => {
  let timeline;

  ScrollTrigger.matchMedia({
    [breakpoints.xl.mediaQuery]: () => {
      timeline = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: () => marker.offsetTop - parseDecimal(element.style.top),
          end: () => `+=${metabarHeightValue}`,
          scroller: document.body,
          scrub: true,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          onLeave: () => calculateTotalStickyHeight(),
        },
      });

      timeline.to(element, { y: -metabarHeightValue });
    },
  });

  subscribeToMessage(messages.refreshTimelines, () => {
    recalculateStickyPositions();
    // Call refresh method to force start positions of sticky elements to be recalculated
    timeline?.scrollTrigger?.refresh();
  });
};

const setStickyAnimation = (element, marker, subscribeToMessage) => {
  if (checkHeaderExistence()) {
    initGsapAnimation(element, marker, subscribeToMessage);
    return;
  }

  subscribeToMessage(messages.headerMount, () => initGsapAnimation(element, marker, subscribeToMessage));
};

export default setStickyAnimation;
