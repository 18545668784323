var $class="se2--utilities",$name="utilities",$path="app/components/utilities/checkIsIncorrect.js",$this={$class,$name,$path,};import every from 'lodash/every';

const formGroupClassName = 'form-group';

function checkValidity(form) {
  if (form.classList.contains('incorrect')) {
    return false;
  }
  return true;
}

const checkIsIncorrect = (form) => every(
  form.querySelectorAll(`.${formGroupClassName}`),
  checkValidity,
);

export default checkIsIncorrect;
