var $class="se2--submit",$name="submit",$path="app/partials/submit/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import addUtmParamsToFormData from 'app/components/utilities/addUtmParamsToFormData';
import validateFormElements from 'app/components/utilities/validateFormElements';

import toBoolean from 'app/utilities/toBoolean';

import { css, form as formConfig, messages, statuses } from 'configs';

import every from 'lodash/every';

const customDropzoneSelector = '.custom-dropzone';
const formBlockDisabledClassName = 'form-block-disabled';
const loaderIconClassName = 'se2-icon-loader';

export default shell.registerPartial($this, ({ addEventListener, mount, publishMessage }) => {
  const toggleFormDisabledState = (form, submitButton, isLoading = true) => {
    form.classList.toggle(formBlockDisabledClassName, isLoading);
    submitButton.classList.toggle(loaderIconClassName, isLoading);
  };

  const validateCourseFinderForm = (form) => {
    if (!toBoolean(form.dataset.queryStringShouldBeChecked)) return true;
    const isValid = every(
      Object.values(formConfig.fieldNames),
      (name) => form.elements[name].value,
    );
    form.parentElement.querySelector(`.${css.classNames.queryErrorMessage}`)
      .classList
      .toggle(css.classNames.hide, isValid);
    return isValid;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { target } = event;
    const submitButton = target.closest('button');
    const form = submitButton.closest('form');
    // Need to run both validations for FormCourseFinder for better user experience
    // eslint-disable-next-line no-bitwise
    const isValidForm = validateCourseFinderForm(form) & validateFormElements(form);

    if (!isValidForm) return;

    addUtmParamsToFormData(form);
    toggleFormDisabledState(form, submitButton);

    const formId = form.id;
    const action = form.getAttribute('action');
    const method = form.getAttribute('method');
    const headers = new Headers({ Accept: 'application/json' });
    const body = new FormData(form);

    body.set('subject', body.getAll('subject').filter((string) => string).join(', '));
    fetch(action, {
      method,
      headers,
      body,
    }).then(({ status }) => {
      // eslint-disable-next-line no-shadow
      const publishFormSubmitMessage = (status) => publishMessage(messages.formSubmit, { status, formId });
      if (status >= 200 && status < 300) {
        publishFormSubmitMessage(statuses.accepted);
      } else if (status >= 300 && status < 400) {
        publishFormSubmitMessage(statuses.redirected);
      } else if (status >= 400 && status < 500) {
        publishFormSubmitMessage(statuses.rejected);
      } else if (status >= 500) {
        publishFormSubmitMessage(statuses.serverError);
      }
    })
      .finally(() => {
        toggleFormDisabledState(form, submitButton, false);
      });
  };

  mount((element) => {
    const dropzone = element.closest('form')?.querySelector(customDropzoneSelector);

    if (dropzone) return;

    const submitButton = element.querySelector('button');
    addEventListener(submitButton, 'click', onSubmit);
  });
});
