var $class="se2--utilities",$name="utilities",$path="app/components/utilities/initializeSlider.js",$this={$class,$name,$path,};import gsap from 'gsap';
import Draggable from 'gsap/Draggable';

import lazyHandleOpenModalButtons from 'app/components/utilities/lazyHandleOpenModalButtons';

import settings from 'app/modules/shell/settings';

import capArrayIndex from 'app/utilities/capArrayIndex';
import getTotalStickyHeight from 'app/utilities/getTotalStickyHeight';
import setAriaAttributes from 'app/utilities/setAriaAttributes';
import setTabIndex from 'app/utilities/setTabIndex';

import { css, delays, directions, gsapAnimation, mouseButtons } from 'configs';

import forEach from 'lodash/forEach';
import map from 'lodash/map';
import throttle from 'lodash/throttle';
import toogleControlVisibility from './toogleControlVisibility';

import handleSlidesNavigation from './handleSlidesNavigation';

const activeSlideClassName = 'active-slide';
const controlsSelector = '.controls';
const nextButtonElementSelector = '.swiper-button-next';
const prevButtonElementSelector = '.swiper-button-prev';
const slideElementSelector = '.slide';
const swiperContainerElementSelector = '.swiper-container';
const uberflipContainerSelector = '.uberflip-cards';

export const interactiveElementsSelector = 'a, .se-rich-text a, button, iframe';

function initializeSlider(element, addEventListener, {
  containerSelector = uberflipContainerSelector,
  isArticleSlides = false,
  nextButtonSelector = nextButtonElementSelector,
  paginationBulletSelector = null,
  prevButtonSelector = prevButtonElementSelector,
  preventKeyNavigation = false,
  slideSelector = slideElementSelector,
  swiperContainerSelector = swiperContainerElementSelector,
  withInteractiveElements = false,
  onNavigation = () => {},
  afterNavigation = () => {},
} = {}) {
  let bulletElements = null;

  if (isArticleSlides) {
    bulletElements = element.querySelectorAll(paginationBulletSelector);
  }

  const cardsContainerElement = element.querySelector(containerSelector);
  const controlsElement = element.querySelector(controlsSelector);
  const prevButtonElement = element.querySelector(prevButtonSelector);
  const nextButtonElement = element.querySelector(nextButtonSelector);
  const slideElements = element.querySelectorAll(slideSelector);

  toogleControlVisibility(slideElements, cardsContainerElement, controlsElement);

  let activeSlide = null;
  let previousActiveSlide = null;
  const maxSlidesQuantityForPagination = 7;

  if (isArticleSlides) {
    forEach(
      slideElements,
      (slide) => {
        forEach(
          slide.querySelectorAll(interactiveElementsSelector),
          (el) => setTabIndex(el, false),
        );
      },
    );
  }

  let positions = map(slideElements, (slide) => (
    settings.direction === directions.rtl
      ? slide.getBoundingClientRect().right - cardsContainerElement.getBoundingClientRect().right
      : slide.offsetLeft - cardsContainerElement.offsetLeft));

  const scrollWindowToSlideTop = (slide) => {
    if (slide.parentElement.getAttribute('role') === 'dialog') return;
    const totalStickyHeight = getTotalStickyHeight() ?? 0;
    const { top } = slide.getBoundingClientRect();
    if (top < totalStickyHeight) {
      window.scrollTo({
        top: window.scrollY + top - totalStickyHeight,
        behavior: 'smooth',
      });
    }
  };

  const selectSlide = (i, animation = true) => {
    previousActiveSlide = activeSlide;
    activeSlide = capArrayIndex(i, slideElements);

    gsap[animation ? 'to' : 'set'](slideElements, {
      x: -positions[activeSlide],
      duration: gsapAnimation.slideDuration,
      onUpdate: () => onNavigation(activeSlide),
      onComplete: () => afterNavigation(activeSlide),
      ease: 'none',
    });

    if (
      isArticleSlides
      && slideElements.length <= maxSlidesQuantityForPagination
      && slideElements.length > 1
    ) {
      bulletElements[previousActiveSlide]?.classList.remove(css.classNames.active);
      bulletElements[previousActiveSlide]?.removeAttribute('aria-disabled');
      bulletElements[activeSlide]?.classList.add(css.classNames.active);
      bulletElements[activeSlide]?.setAttribute('aria-disabled', true);
    }

    if (slideElements[previousActiveSlide]) {
      slideElements[previousActiveSlide].classList.remove(activeSlideClassName);

      if (isArticleSlides) {
        setTabIndex(slideElements[previousActiveSlide], false);
        setAriaAttributes(slideElements[previousActiveSlide], { hidden: true });
        forEach(
          slideElements[previousActiveSlide].querySelectorAll(interactiveElementsSelector),
          (el) => setTabIndex(el, false),
        );
      }
    }

    slideElements[activeSlide]?.classList.add(activeSlideClassName);

    if (isArticleSlides) {
      slideElements[activeSlide].removeAttribute('tabindex');
      setAriaAttributes(slideElements[activeSlide], { hidden: false });
      forEach(
        slideElements[activeSlide].querySelectorAll(interactiveElementsSelector),
        (el) => el.removeAttribute('tabindex'),
      );

      scrollWindowToSlideTop(slideElements[activeSlide]);
    }
  };

  const checkNextSlideAvailable = () => cardsContainerElement.scrollWidth - cardsContainerElement.clientWidth
    > 0 && activeSlide !== slideElements.length - 1;

  if (slideElements.length > 1) {
    addEventListener(controlsElement, 'click', throttle(({ target }) => {
      if (target === nextButtonElement && checkNextSlideAvailable()) {
        selectSlide(activeSlide + 1);
      } else if (target === prevButtonElement && activeSlide !== 0) {
        selectSlide(activeSlide - 1);
      } else if (paginationBulletSelector && target.closest(paginationBulletSelector)) {
        selectSlide(+target.closest(paginationBulletSelector).dataset.index);
      }
    }, delays.ms300));
  }

  selectSlide(0);

  // TODO: temporary solution. Needs to combine handleSlidesNavigation and createSlidesOnKeydownListener
  if (!preventKeyNavigation) {
    const onContainerKeyDown = (event) => {
      handleSlidesNavigation(
        event,
        prevButtonElement,
        nextButtonElement,
        withInteractiveElements,
      );
    };

    addEventListener(cardsContainerElement, 'keydown', onContainerKeyDown);
  }

  const phantomElement = document.createElement('div');
  let dragStart = 0;

  const onDragStart = (event) => {
    dragStart = event.clientX;
  };

  const onDragEnd = (event) => {
    const offset = settings.direction === directions.rtl
      ? event.clientX - dragStart
      : dragStart - event.clientX;

    if (offset < 0 && activeSlide !== 0) {
      selectSlide(activeSlide - 1);
      if (isArticleSlides) scrollWindowToSlideTop(slideElements[activeSlide]);
    } else if (offset > 0 && checkNextSlideAvailable()) {
      selectSlide(activeSlide + 1);
      if (isArticleSlides) scrollWindowToSlideTop(slideElements[activeSlide]);
    }
  };

  const onClick = (event) => {
    if (event.button === mouseButtons.middle) {
      event.preventDefault();
    }
  };

  lazyHandleOpenModalButtons(addEventListener, element);

  return {
    draggable: new Draggable(
      phantomElement,
      {
        type: 'x',
        // according to the https://www.researchgate.net average swipe distance on mobile is 18% from screen width
        minimumMovement: window.innerWidth * gsapAnimation.recognitionRate,
        throwProps: true,
        allowContextMenu: true,
        dragClickables: true,
        cursor: 'default',
        activeCursor: 'grabbing',
        trigger: element.querySelector(swiperContainerSelector),
        onDragStart,
        onDragEnd,
        onClick,
      },
    ),
    resetPosition: () => {
      gsap.set(slideElements, { clearProps: true });
    },
    onResize: () => {
      const cardsOffsetRight = cardsContainerElement.offsetLeft + cardsContainerElement.offsetWidth;

      positions = map(slideElements, (slide) => {
        const slideOffsetRight = slide.offsetLeft + slide.offsetWidth;

        return settings.direction === directions.rtl
          ? slideOffsetRight - cardsOffsetRight
          : slide.offsetLeft - cardsContainerElement.offsetLeft;
      });

      gsap.set(slideElements, {
        x: -positions[activeSlide],
      });

      toogleControlVisibility(slideElements, cardsContainerElement, controlsElement);
    },
    setActiveElement: (openedSlideIndex) => {
      selectSlide(openedSlideIndex, false);
    },
    element,
  };
}

export default initializeSlider;
