var $name="breakpoints",$path="configs/breakpoints.js",$this={$name,$path,};const toMediaQuery = require('./utilities/toMediaQuery');

class Breakpoint {
  constructor(key, { max, min }) {
    this.key = key;
    this.max = max;
    this.min = min;
    this.mediaQuery = toMediaQuery(this);
  }
}

const breakpoints = {
  xl: new Breakpoint('xl', { min: 1200 }),
  xs: new Breakpoint('xs', { max: 1199 }),
};

module.exports = breakpoints;
